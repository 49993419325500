.firebaseui-idp-password,
.firebaseui-idp-password:hover,
.mdl-button.firebaseui-idp-password:active,
.mdl-button.firebaseui-idp-password:focus {
  background-color: #002a82 !important;
}


.firebaseui-container{
  box-shadow: none;
  background-color: red;
}